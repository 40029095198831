<template>
    <div class="scan-page">

        <!-- Barcode picker -->
        <scanner
            @barcodeHasBeenScanned="barcodeWasScanned($event)"
        ></scanner>

        <!-- Empty state -->
        <empty-state
                v-if="!shipmentIdBeingReturned"
                icon="scanner"
                title="Scan a shipment">
        </empty-state>

        <!-- Outbound shipment scanned --->
        <template v-if="shipmentIdBeingReturned">
            <!-- Details -->
            <div class="scan-details">
                <div class="container">
                    <h4 class="d-flex align-items-center mb-2">Return shipment {{ shipmentIdBeingReturned }} <button class="btn btn-success btn-sm ml-auto" @click="$bvModal.show('complete-return-shipment-modal')">Complete return</button></h4>
                    <h5 class="text-subtitle mb-0">{{ repairKitsShippedBack.length }} repair kits added</h5>
                </div>
            </div>

            <!-- Item list -->
            <div class="container scan-content">
                <div class="card-grid vertical" v-if="repairKits.length > 0">
                    <div
                            v-for="repairKit in repairKits"
                            :key="repairKit.id"
                            class="card-link-container">
                        <div class="card card-body card-body-sm">
                            Repair kit: {{ repairKit.id }}
                        </div>
                    </div>
                </div>
                <div class="card" v-if="repairKits.length < 1">
                    <empty-state
                            icon="scanner"
                            title=""
                            subtitle="Scan the repair kits you're returning in this shipment">
                    </empty-state>
                </div>
            </div>
        </template>

        <!-- Complete shipment modal -->
        <return-shipment-complete-modal></return-shipment-complete-modal>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        data() {
            return {
                pageTitle: null,
            }
        },

        computed: {
            ...mapGetters([
                'appRegion',
                'repairKitForFsl',
                'repairKits',
                'repairKitsShippedBack',
                'shipmentIdBeingReturned'
            ]),
        },

        watch: {
            repairKitsPicked() {
                this.checkShipmentComplete();
            }
        },

        mounted() {
            this.clearShipmentScanned();
            this.clearReturnShipmentScanned();
        },

        methods: {
            ...mapActions([
                'clearShipmentScanned',
                'clearReturnShipmentScanned',
                'displayToast',
                'loadReturnShipmentDetails',
                'setShipmentScanned',
                'setReturnShipmentScanned',
                'setRepairKitAsShippedBack',
                'setRepairKitAsShippedBackDirectly',
                'loadRepairKitForFsl',
            ]),

            /** Check the barcode type */
            barcodeWasScanned(barcodeScanned) {
                if (barcodeScanned.type === 'returnShipment') {
                    this.shipmentScanned(barcodeScanned);
                }

                if (barcodeScanned.type === 'repairKit') {
                    this.loadRepairKitForFsl({
                        repairKitId: barcodeScanned.id
                    }).then(() => {
                        if (this.repairKitForFsl.fsl_kit_id) {
                            if (this.repairKitForFsl.state_constant === 'SHIPPED_BACK') {
                                this.displayToast({text: 'This kit has already been scanned', type: 'error'});
                                return;
                            }

                            this.setRepairKitAsShippedBackDirectly(this.repairKitForFsl.id).then(() => {
                                this.displayToast({text: 'This FSL kit has been marked as shipped back', type: 'success'});
                            });
                        } else {
                            if (!this.shipmentIdBeingReturned) {
                                this.displayToast({text: 'Please scan a return shipment first', type: 'error'});
                                return;
                            }

                            this.setRepairKitAsShippedBack(barcodeScanned.id);
                        }
                    });
                }
            },

            /** Shipment scanned */
            shipmentScanned(barcodeScanned) {
                this.clearReturnShipmentScanned().then(() => {
                    this.loadReturnShipmentDetails({
                        barcodeScanned: barcodeScanned
                    }).then(() => {
                        this.setReturnShipmentScanned(barcodeScanned.id);
                    });
                });
            }

        }
    }
</script>

<style scoped>

</style>